var config = {
  firebase: {
    apiKey: "AIzaSyAPk7EjIs6hgDnD9zm9DHQf8KsrQWa0ecU",
    authDomain: "observatorio-cancer-infantil.firebaseapp.com",
    databaseURL: "https://observatorio-cancer-infantil.firebaseio.com",
    projectId: "observatorio-cancer-infantil",
    storageBucket: "observatorio-cancer-infantil.appspot.com",
    messagingSenderId: "768534289508",
    appId: "1:768534289508:web:1bbe41038dba98da5b1371",
    measurementId: "G-RQW6TZ7P35",
  },
};

module.exports = config;
